.page-not-found {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vh 5vw;
  min-height: 70vh;

  .error-404-page-container {
    width: 100%;
    height: 100%;
    max-width: 2000px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .error-404-page-text {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .error-404-page-content {
        width: 400px;

        .error-404-heading {
          color: red;
          font-weight: bolder;
          font-size: 30px;
          margin: 10px 0;
        }

        .error-404-info {
          font-size: 16px;
          margin: 10px 0;
          width: 80%;
        }

        .error-404-button {
          margin: 20px 0;
          margin-top: 50px;

          a {
            text-decoration: none;
            color: white;
            font-size: 14px;
            font-weight: normal;
            background: green;
            padding: 8px 20px;
            padding-bottom: 10px;
            margin: 0px;
            border-radius: 50px;
            box-sizing: 0 2px 4px -1px #222;
          }
        }
      }
    }

    .error-404-page-image {
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .error-404-image {
        width: 550px;
        height: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .page-not-found {
    .error-404-page-container {
      .error-404-page-text {
        min-width: 50%;
        width: auto;

        .error-404-page-content {
          width: 100%;
        }
      }

      .error-404-page-image {
        flex: 1;

        .error-404-image {
          width: 500px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .page-not-found {
    .error-404-page-container {
      flex: 1;
      .error-404-page-text {
        width: 100%;

        .error-404-page-content {
          width: 100%;
        }
      }

      .error-404-page-image {
        display: none;
      }
    }
  }
}
